import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext'; // For managing user context if needed
import Footer from './Footer';
import Img from "../static/images/rb_14762.png"; // Using the same image as in Login

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { setUsername } = useUser(); // Using setUsername to store the name/email from backend
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            const response = await fetch('http://20.151.63.137/api/signup/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                // On success, set the user name in context/localStorage and navigate to login
                if (data.name) {
                    setUsername(data.name);
                    localStorage.setItem('username', data.name);
                } else {
                    setUsername(data.email);
                    localStorage.setItem('username', data.email);
                }

                setSuccess('Signup successful! Please log in.');
                setEmail('');
                setName('');
                setPassword('');
                navigate('/login'); // Redirect to login
            } else {
                setError(data.error || 'Signup failed');
            }
        } catch (error) {
            console.error('Error during signup:', error);
            setError('Signup failed: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="vh-100">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col col-xl-10">
                        <div className="card border-0" style={{ borderRadius: '1rem' }}>
                            <div className="row g-0">
                                {/* Image is now on the left */}
                                <div className="col-md-6 col-lg-5 d-none d-md-block">
                                    <img
                                        src={Img} // Using the same image as in Login
                                        alt="signup form"
                                        className="img-fluid"
                                        style={{ borderRadius: '1rem 0 0 1rem', height: '100%', objectFit: 'cover' }} 
                                    />
                                </div>
                                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                    <div className="card-body p-4 p-lg-5 text-black">
                                        <form onSubmit={handleSubmit}>
                                            {/* <div className="d-flex align-items-center mb-3 pb-1">
                                                <i className="fas fa-cubes fa-2x me-3" style={{ color: '#ff6219' }}></i>
                                                <span className="h1 fw-bold mb-0">Logo</span>
                                            </div> */}

                                            <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: '1px' }}>
                                                Create your account
                                            </h5>

                                            {error && <div className="alert alert-danger">{error}</div>}
                                            {success && <div className="alert alert-success">{success}</div>}

                                            <div className="form-outline mb-4">
                                                <input
                                                    type="text"
                                                    id="form2Example18"
                                                    className="form-control form-control-lg"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    required 
                                                />
                                                <label className="form-label" htmlFor="form2Example18">Name</label>
                                            </div>

                                            <div className="form-outline mb-4">
                                                <input
                                                    type="email"
                                                    id="form2Example19"
                                                    className="form-control form-control-lg"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required 
                                                />
                                                <label className="form-label" htmlFor="form2Example19">Email address</label>
                                            </div>

                                            <div className="form-outline mb-4">
                                                <input
                                                    type="password"
                                                    id="form2Example20"
                                                    className="form-control form-control-lg"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required 
                                                />
                                                <label className="form-label" htmlFor="form2Example20">Password</label>
                                            </div>

                                            <div className="pt-1 mb-4">
                                                <button
                                                    className="btn btn-dark btn-lg btn-block"
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Signing Up...' : 'Sign Up'}
                                                </button>
                                            </div>

                                            <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>
                                                Already have an account? <a href="/login" style={{ color: '#393f81' }}>Log in here</a>
                                            </p>
                                            <a href="#!" className="small text-muted me-2">Terms of use</a>
                                            <a href="#!" className="small text-muted">Privacy policy</a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer /> {/* Including the footer if needed */}
        </section>
    );
};

export default SignUp;
