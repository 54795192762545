import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import Footer from "./Footer";
import Img from "../static/images/rb_14762.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUsername } = useUser();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://20.151.63.137/api/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("accessToken", data.access);
        localStorage.setItem("refreshToken", data.refresh);

        if (data.name) {
          setUsername(data.name);
          localStorage.setItem("username", data.name);
        } else {
          setUsername(data.email);
          localStorage.setItem("username", data.email);
        }

        navigate("/"); // Redirect to home page
      } else {
        console.error("Login failed:", data);
        alert(data.error || "Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <section className="vh-100">
        <div className="container h-75">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-xl-10">
              <div className="card border-0" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  {/* Form is on the left */}
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={handleLogin}>
                        <h5
                          className="fw-normal mb-3 pb-3 text-center"
                          style={{ letterSpacing: "1px" }}
                        >
                          Sign into your account
                        </h5>

                        <div className="form-outline mb-4">
                          <label htmlFor="form2Example17" className="form-label">
                            Email address
                          </label>
                          <input
                            type="email"
                            id="form2Example17"
                            className="form-control form-control-lg"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label htmlFor="form2Example27" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            id="form2Example27"
                            className="form-control form-control-lg"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>

                        <div className="pt-1 mb-4">
                          <button
                            className="btn btn-dark btn-lg btn-block w-100"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>

                        <div className="text-center">
                          <a className="small text-muted d-block mb-2" href="#!">
                            Forgot password?
                          </a>
                          <p style={{ color: "#393f81" }}>
                            Don't have an account?{" "}
                            <a href="/signup" style={{ color: "#393f81" }}>
                              Register here
                            </a>
                          </p>
                        </div>

                        <div className="d-flex justify-content-center gap-3 mt-4">
                          <a href="#!" className="small text-muted">
                            Terms of use
                          </a>
                          <a href="/privacy-policy" className="small text-muted">
                            Privacy policy
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>

                  {/* Image is on the right */}
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src={Img}
                      alt="login form"
                      className="img-fluid"
                      style={{
                        borderRadius: "0 1rem 1rem 0",
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Login;
