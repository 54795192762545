import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

const FileUpload = () => {
    const [excelFile1, setExcelFile1] = useState(null);
    const [excelFile2, setExcelFile2] = useState(null);
    const [comparisonResults, setComparisonResults] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // Handle Excel file 1 selection
    const handleExcel1Change = (event) => {
        setExcelFile1(event.target.files[0]);
    };

    // Handle Excel file 2 selection
    const handleExcel2Change = (event) => {
        setExcelFile2(event.target.files[0]);
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null); // Clear previous errors
        setLoading(true); // Indicate loading state

        if (!excelFile1 || !excelFile2) {
            setError('Please upload both Excel files.');
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('excel_file_1', excelFile1);
        formData.append('excel_file_2', excelFile2);

        try {
            const response = await fetch('http://localhost:8000/api/comparison/upload/', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload files');
            }

            const data = await response.json();
            console.log('Comparison Results:', data);  // Debugging line

            if (data && data.comparison_results) {
                setComparisonResults(data.comparison_results);
            } else {
                throw new Error('Comparison results not found in the response');
            }
        } catch (error) {
            setError(`Error: ${error.message}`);
        } finally {
            setLoading(false); // Stop loading state
        }
    };

    // Prepare chart data from comparison results
    const prepareChartData = () => {
        if (!comparisonResults) return [];

        // Assuming comparison_results is a list of objects with columnName and count properties
        return comparisonResults.map((result, index) => ({
            name: result.columnName || `Column ${index + 1}`,
            count: result.count || 0,  // Assuming count is part of result
        }));
    };

    const chartData = prepareChartData();
    console.log('Chart Data:', chartData);  // Debugging line

    return (
        <div>
            <h2>Upload Two Excel Files</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Excel File 1:
                        <input type="file" accept=".xlsx,.xls" onChange={handleExcel1Change} required />
                    </label>
                </div>
                <div>
                    <label>
                        Excel File 2:
                        <input type="file" accept=".xlsx,.xls" onChange={handleExcel2Change} required />
                    </label>
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Uploading...' : 'Upload'}
                </button>
            </form>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            {comparisonResults && (
                <div>
                    <h3>Comparison Results</h3>
                    <BarChart width={600} height={300} data={chartData}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Legend />
                        <Bar dataKey="count" fill="#8884d8" />
                    </BarChart>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
