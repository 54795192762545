import React from "react";
import Logo from '../static/images/Fulllogo.png'; // Ensure this path is correct
import { Link } from 'react-router-dom'; // Import Link for navigation

// Company name: Signoff AI
const Footer = () => {
  return (
    <div className="container-fluid">
    <footer className="py-3 my-4 border-top container">
      <div className="d-flex justify-content-center align-items-center">
        <img 
          src={Logo} 
          alt="Company Logo" 
          style={{ width: 'auto', height: '40px' }} // Adjust size as needed
        />
        <div className="text-body-secondary">
          <p className="mb-0">© 2024 Signoff ai, Inc<Link to="/privacy-policy" className="mx-2 text-body-secondary">
              Privacy Policy
            </Link>
            |
            <Link to="/terms-and-conditions" className="mx-2 text-body-secondary">
              Terms and Conditions
            </Link></p>
          
        </div>
      </div>
    </footer>
  </div>
  );
};

export default Footer;
