import React from 'react';
import './static/css/App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SignUp from './componentes/Signup';
import Login from './componentes/Login';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { UserProvider } from './componentes/UserContext'; // Import the UserContext
import NavBar from './componentes/Navbar';
import LoanCalculator from './componentes/LoanCalculator';
import Hero from './componentes/Hero';
import Card_C from './componentes/Card_C';
import Footer from './componentes/Footer';
import EmailForm from './componentes/Email';
import 'react-datepicker/dist/react-datepicker.css';
import SecurePage from './componentes/SecurePage';
import FileUpload from './pages/FileUpload';
import Calender from './pages/Calender';
import Products from './pages/Products'; // Assuming you have a Products component

const App = () => {
  return (
    <UserProvider>
      <Router>
        <AppRoutes />
      </Router>
    </UserProvider>
  );
};

const AppRoutes = () => {
  const location = useLocation();

  // List of paths where the NavBar and Footer should NOT be displayed
  const noNavBarFooterRoutes = ['/products']; // Add more paths as needed

  const isNoNavBarFooterRoute = noNavBarFooterRoutes.includes(location.pathname);

  return (
    <>
      {!isNoNavBarFooterRoute && <NavBar />}
      <div className="container-fluid p-0"> {/* Use full width container and remove padding */}
        <Routes>
          {/* <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} /> */}
          <Route path="/email" element={<EmailForm />} />
          {/* <Route path="/secure" element={<SecurePage />} /> */}
          {/* <Route path="/comparison" element={<FileUpload />} />
          <Route path='/calendar' element={<Calender />} /> */}
          <Route path='/products' element={<Products />} /> {/* New Products route */}
          {/* Home Route: Hero and Card Components */}
          <Route path="/" element={
            <>
              <Hero />
              {/* <Card_C /> */}
            </>
          } />
        </Routes>
      </div>
      {!isNoNavBarFooterRoute && <Footer />}
    </>
  );
};

export default App;
