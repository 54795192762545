import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const HorizontalCard = ({ imageOnRight }) => {
  return (
    <div className="card-container container my-5">
      <Card className="h-25 w-100 border-0 rounded-0">
        <Row noGutters>
          {/* If imageOnRight is true, render the text first, otherwise render the image first */}
          {imageOnRight ? (
            <>
              <Col md={8}> {/* Text first */}
                <Card.Body>
                  <Card.Title>Horizontal Card</Card.Title>
                  <Card.Text>
                    This is a wider card with supporting text as a natural lead-in to additional content.
                    This content is a little bit longer.
                  </Card.Text>
                </Card.Body>
              </Col>
              <Col md={4}> {/* Image second */}
                <Card.Img src="https://picsum.photos/400/400/?image=20" alt="Image" className="rounded-0 h-100" />
              </Col>
            </>
          ) : (
            <>
              <Col md={4}> {/* Image first */}
                <Card.Img src="https://picsum.photos/400/400/?image=20" alt="Image" className="rounded-0 h-100" />
              </Col>
              <Col md={8}> {/* Text second */}
                <Card.Body>
                  <Card.Title>Horizontal Card</Card.Title>
                  <Card.Text>
                    This is a wider card with supporting text as a natural lead-in to additional content.
                    This content is a little bit longer.
                  </Card.Text>
                </Card.Body>
              </Col>
            </>
          )}
        </Row>
      </Card>
    </div>
  );
};

export default HorizontalCard;
