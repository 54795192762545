import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import Logo from "../static/images/Fulllogo.png";
import { Button, Dropdown, Navbar, Nav, Container } from "react-bootstrap";

const NavBar = () => {
    const { username, setUsername } = useUser(); // Access username and setUsername from context
    const navigate = useNavigate();

    const handleLogout = async () => {
        const refresh = localStorage.getItem("refreshToken");
        if (!refresh) {
            console.error("No refresh token found");
            return;
        }
        try {
            const response = await fetch("http://20.151.63.137/api/logout/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ refresh }),
            });
            if (response.ok) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("username"); // Remove username from localStorage
                setUsername(null); // Clear context state
                // navigate("/login");
            } else {
                console.error("Logout failed");
            }
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    const scrollToContactUs = () => {
        const element = document.getElementById("contact_us");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <div
                className="alert alert-primary rounded-0"
                role="alert"
                style={{
                    height: "40px", // Adjust height for better visibility
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingLeft: "20px",
                    fontWeight: "bold",
                }}
            >
                Under development: all features will be added soon
            </div>
            <Container>
                <Navbar expand="lg" bg="" variant="light">
                    <Container fluid>
                        <Navbar.Brand as={Link} to="/">
                        <img
            src={Logo}
            alt="logo"
            height="80px"
            style={{
                objectFit: 'cover',
                objectPosition: 'top',
                width: 'auto',
            }}
        />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbar-nav" />
                        <Navbar.Collapse id="navbar-nav">
                            <Nav className="ms-auto d-flex align-items-end gap-3">
                                {!username ? (
                                    <>
                                        <Nav.Item>
                                            {/* <Link className="btn btn-primary" to="/login">
                                                Login
                                            </Link> */}
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Button onClick={scrollToContactUs}>
                                                Talk to us
                                            </Button>
                                        </Nav.Item>
                                    </>
                                ) : (
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" id="dropdown-basic" className="nav-link">
                                            Welcome, {username}!
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={Link} to="/products">
                                                Products
                                            </Dropdown.Item> */
                                            {/* {/* <Dropdown.Item as={Link} to="/services">
                                                Services
                                            </Dropdown.Item> */}
                                            {/* <Dropdown.Item onClick={scrollToContactUs}>
                                                Contact Us
                                            </Dropdown.Item> */}
                                            <Dropdown.Divider />
                                            {/* <Dropdown.Item as="button" onClick={handleLogout}>
                                                Logout
                                            </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
        </>
    );
};

export default NavBar;
