import React, { createContext, useContext, useEffect, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [username, setUsername] = useState(() => {
        // Retrieve the username from local storage if it exists
        return localStorage.getItem('username') || null;
    });

    useEffect(() => {
        // Store the username in local storage whenever it changes
        if (username) {
            localStorage.setItem('username', username);
        } else {
            localStorage.removeItem('username'); // Remove if not logged in
        }
    }, [username]);

    return (
        <UserContext.Provider value={{ username, setUsername }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
