// SecurePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SecurePage = () => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Track authentication status
    const [loading, setLoading] = useState(true); // Track loading state

    useEffect(() => {
        const fetchSecureData = async () => {
            const token = localStorage.getItem('demo_token');  // Retrieve the token from localStorage

            if (!token) {
                setError('No token found. Please schedule a demo first.');
                setLoading(false); // Stop loading if no token is found
                return;
            }

            try {
                // Send a request to the secure endpoint
                const response = await axios.get('http://localhost:8000/api/secure-demo/', {
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Include the token in the request header
                    },
                });

                setMessage(response.data.message);  // Set the message from the response
                setIsAuthenticated(true); // Set authenticated status to true
            } catch (error) {
                console.error('Error fetching secure data', error);
                // Check if the error response indicates unauthorized access
                if (error.response && error.response.status === 401) {
                    setError('Unauthorized access. Please check your token and try again.');
                } else {
                    setError('Failed to access secure page. Please ensure your token is valid.');
                }
            } finally {
                setLoading(false); // Stop loading after the request completes
            }
        };

        fetchSecureData();
    }, []);  // Empty dependency array to run once on mount

    return (
        <div>
            <h1>Secure Page</h1>
            {loading && <p>Loading...</p>} {/* Display loading state */}
            {isAuthenticated && <p style={{ color: 'green' }}>Authenticated successfully!</p>} {/* Success message */}
            {error && <p style={{ color: 'red' }}>{error}</p>}  {/* Display any errors */}
            {message && <p>{message}</p>}  {/* Display the secure message */}
        </div>
    );
};

export default SecurePage;
