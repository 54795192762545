// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add a smooth hover grow effect and shadow */
.hover-effect {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 10px;
    padding: 20px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    -webkit-user-select: none;
            user-select: none;
  }
  
  .hover-effect:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-body {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: 500;
  }
  
  .card-text {
    font-size: 1rem;
    color: #6c757d;
  }
  `, "",{"version":3,"sources":["webpack://./src/static/css/Card.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;IACI,mEAAmE;IACnE,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,wCAAwC;IACxC,yBAAiB;YAAjB,iBAAiB;EACnB;;EAEA;IACE,sBAAsB;IACtB,yCAAyC;EAC3C;;EAEA;IACE,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,cAAc;EAChB","sourcesContent":["/* Add a smooth hover grow effect and shadow */\n.hover-effect {\n    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;\n    border-radius: 10px;\n    padding: 20px;\n    background-color: #f8f9fa;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    user-select: none;\n  }\n  \n  .hover-effect:hover {\n    transform: scale(1.05);\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n  }\n  \n  .card-body {\n    height: 150px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n  \n  .card-title {\n    font-size: 1.5rem;\n    font-weight: 500;\n  }\n  \n  .card-text {\n    font-size: 1rem;\n    color: #6c757d;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
