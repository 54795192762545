import { useState } from 'react';
import Footer from './Footer';

const EmailForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    telephone: '',
    email: '',
    note: '',
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission (you can replace this with actual submission logic)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await fetch('https://www.signoffai.com/api/contact/', {  // Adjust URL if needed
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),  // Convert formData to JSONd
        });

        if (response.ok) {
            alert('Thank you! We will contact you soon.');
            setFormData({ name: '', company: '', telephone: '', email: '', note: '' }); // Reset form
        } else {
            const errorData = await response.json();
            alert('Error submitting form: ' + JSON.stringify(errorData.errors));
        }
    } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while submitting the form.');
    }
};

  return (
    <><div className="container my-5 w-50">
          <h2 className="mb-4">Contact Us</h2>

          <form onSubmit={handleSubmit}>
              <div className="mb-3">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required />
              </div>

              <div className="mb-3">
                  <label htmlFor="company" className="form-label">Company</label>
                  <input
                      type="text"
                      className="form-control"
                      id="company"
                      name="company"
                      value={formData.company}
                      onChange={handleChange} />
              </div>

              <div className="mb-3">
                  <label htmlFor="telephone" className="form-label">Telephone</label>
                  <input
                      type="tel"
                      className="form-control"
                      id="telephone"
                      name="telephone"
                      value={formData.telephone}
                      onChange={handleChange}
                      required />
              </div>

              <div className="mb-3">
                  <label htmlFor="email" className="form-label">E-mail</label>
                  <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required />
              </div>

              <div className="mb-3">
                  <label htmlFor="note" className="form-label">Note</label>
                  <textarea
                      className="form-control"
                      id="note"
                      name="note"
                      rows="4"
                      value={formData.note}
                      onChange={handleChange}
                  ></textarea>
              </div>

              <button type="submit" className="btn btn-primary">Submit</button>
          </form>
      </div></>
  );
};

export default EmailForm;
