import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import CalendarPopup from './CalendarPopup';
import Card from './Card';
import '../static/css/Hero.css';
import vid from '../static/images/1.mp4';
const Hero = () => {
  const [showCalendar, setShowCalendar] = useState(false);

  // Handle "Request Demo" button click (open the calendar popup)
  const handleDemoRequest = () => {
    setShowCalendar(true);
  };

  // Close calendar popup
  const handleCloseCalendar = () => {
    setShowCalendar(false);
  };

  return (
    <div className="container">
      <div className="pt-5 my-5 text-center">
        <h1 className="container-fluid fw-bold w-75 text-animation-top">
          Faster SignOffs on Audits, Reviews, and Compilations. Powered by AI,
          <br />
          so you can focus on clients.
        </h1>

        {/* Centered Image Section */}
        <div className="container-fluid my-5 text-animation-right">
        <div className="video-container ">
            <video
              src={vid}
              className="video-fluid w-75"
              autoPlay
              loop
              muted
              loading="lazy"
              
            />
          </div>

          {/* Cards Section */}
          <CardSection />
        </div>

        {/* Contact Section */}
        <div className="d-flex flex-column align-items-center text-center my-5" id="contact_us">
          <h2 className="display-4 mb-3">Contact Us</h2>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <Link to="/email">
              <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3">
                Email
              </button>
            </Link>
            {/* <Link to="/calendar" className="btn btn-outline-secondary btn-lg px-4">
              Talk to us
            </Link> */}
          </div>
        </div>

        {/* Calendar Popup */}
        <CalendarPopup show={showCalendar} handleClose={handleCloseCalendar} />
      </div>
    </div>
  );
};

// Separate CardSection Component
const CardSection = () => {
  const cardRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.15, // 20% of the card needs to be visible to trigger the animation
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show'); // Add 'show' class when in view
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) observer.unobserve(card); // Clean up the observer
      });
    };
  }, []);

  return (
    <div className="container">
      {/* First Row: 3 Cards */}
      <div className="row mt-5">
        <div className="col-md-4 mb-4">
          <div className="scroll-animation" ref={(el) => (cardRefs.current[0] = el)}>
            <Card title="Save Time" content="Automate time-consuming tasks in audits, reviews, and compilations." />
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="scroll-animation scroll-animation-delay" ref={(el) => (cardRefs.current[1] = el)}>
            <Card title="Increase Firm Value" content="Grow client base and elevate your firm’s value." />
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="scroll-animation" ref={(el) => (cardRefs.current[2] = el)}>
            <Card title="Enhance Client Development" content="Shift focus from routine tasks to strategic consulting and deepen client relationships.

" />
          </div>
        </div>
      </div>

      {/* Second Row: 2 Cards */}
      <div className="row justify-content-center">
        <div className="col-md-4 mb-4">
          <div className="scroll-animation" ref={(el) => (cardRefs.current[3] = el)}>
            <Card title="Improve Staff Morale" content="Reduce staff burnout by streamlining workflows, increasing staff motivation and engagement.
" />
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="scroll-animation scroll-animation-delay" ref={(el) => (cardRefs.current[4] = el)}>
            <Card title="Accurate Working Papers" content="Utilise state-of-the -art AI technology to generate detailed and precise working papers.
" />
          </div>
        </div>
      </div>

      {/* Third Row: 3 Cards */}
      <div className="row">
        <div className="col-md-4 mb-4">
          <div className="scroll-animation" ref={(el) => (cardRefs.current[5] = el)}>
            <Card title="Ease of Use" content="Access our tools directly through the cloud, without complex installations and setups.
" />
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="scroll-animation scroll-animation-delay" ref={(el) => (cardRefs.current[6] = el)}>
            <Card title="Secure Data Handling" content="Our solution is designed with commercial-grade cloud and AI infrastructure to ensure the utmost security and confidentiality." />
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="scroll-animation" ref={(el) => (cardRefs.current[7] = el)}>
            <Card title="Robust Product Line" content="Explore a range of AI solutions tailored to your needs, with new features and updates always in the pipeline." />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
