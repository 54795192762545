import React from 'react';
import '../static/css/Card.css'; // Importing the CSS file for hover effect

const Card = ({ title, content }) => {
  return (
    <div className="card bootstrap-card hover-effect text-center cursor-arrow">
      <div className="card-body d-flex flex-column justify-content-center align-items-center">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{content}</p>
      </div>
    </div>
  );
};

export default Card;
