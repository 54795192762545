import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios'; // Make sure axios is installed

const CalendarPopup = ({ show, handleClose }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  
  const handleSubmit = async () => {
    if (!selectedDate || !name || !email || !company) {
      alert('Please fill all fields and select a date.');
      return;
    }

    // Prepare data to send to the backend
    const demoData = {
      name,
      email,
      company,
      date: selectedDate // Include the selected date in the request
    };

    try {
      // Send a request to your Django backend
      const response = await axios.post('http://localhost:8000/api/demo-request/', demoData);
      
      // If request is successful, store the token and show confirmation
      const { access_token } = response.data;
      console.log('Access Token:', access_token); // Log to verify the token
      localStorage.setItem('demo_token', access_token); // Store token in localStorage

      alert('Demo scheduled successfully!');
      handleClose(); // Close the modal
    } catch (error) {
      console.error('Error scheduling demo:', error.response ? error.response.data : error.message);
      alert('Failed to schedule demo. Please try again.');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Schedule a Demo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          <h5>Enter your details and select a date</h5>
          
          {/* Form Fields */}
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="form-control mt-3"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control mt-3"
          />
          <input
            type="text"
            placeholder="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            className="form-control mt-3"
          />
          
          {/* Date Picker */}
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            showTimeSelect
            dateFormat="Pp"
            className="form-control mt-3"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Schedule Demo
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarPopup;
