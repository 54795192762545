import React, { useState } from 'react';
import axios from 'axios';

const LoanCalculator = () => {
    const [loanAmount, setLoanAmount] = useState('');
    const [loanTermYears, setLoanTermYears] = useState('');
    const [loanTermMonths, setLoanTermMonths] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const csrftoken = document.cookie.split('; ').find(row => row.startsWith('csrftoken=')).split('=')[1];
        const accessToken = localStorage.getItem('accessToken');

        try {
            const response = await axios.post('http://127.0.0.1:8000/api/loan-calculator/', {
                loan_amount: loanAmount,
                loan_term_years: loanTermYears,
                loan_term_months: loanTermMonths,
                interest_rate: interestRate,
            },{
                headers: {
                    'Authorization': `Bearer ${accessToken}`, // Add the token here
                    'X-CSRFToken': csrftoken,
                }
        });

            setResult(response.data.result);
            setError(null);  // Clear any errors
        } catch (err) {
            setError('Error calculating loan. Please check your input.');
            setResult(null);  // Clear result if there's an error
        }
    };

    return (
        <div>
            <h2>Loan Calculator</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Loan Amount</label>
                    <input
                        type="text"
                        className="form-control"
                        value={loanAmount}
                        onChange={(e) => setLoanAmount(e.target.value)}
                        placeholder="Enter loan amount"
                    />
                </div>
                <div className="form-group">
                    <label>Loan Term (Years)</label>
                    <input
                        type="number"
                        className="form-control"
                        value={loanTermYears}
                        onChange={(e) => setLoanTermYears(e.target.value)}
                        placeholder="Enter loan term in years"
                    />
                </div>
                <div className="form-group">
                    <label>Loan Term (Months)</label>
                    <input
                        type="number"
                        className="form-control"
                        value={loanTermMonths}
                        onChange={(e) => setLoanTermMonths(e.target.value)}
                        placeholder="Enter loan term in months"
                    />
                </div>
                <div className="form-group">
                    <label>Interest Rate (%)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={interestRate}
                        onChange={(e) => setInterestRate(e.target.value)}
                        placeholder="Enter interest rate"
                    />
                </div>
                <button type="submit" className="btn btn-primary">Calculate</button>
            </form>

            {result && <h3>{result}</h3>}
            {error && <p className="text-danger">{error}</p>}
        </div>
    );
};

export default LoanCalculator;
