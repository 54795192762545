// src/components/Products.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import {
    CDBSidebar,
    CDBSidebarHeader,
    CDBSidebarMenuItem,
    CDBSidebarContent,
    CDBSidebarMenu,
    CDBSidebarSubMenu,
    CDBSidebarFooter,
  } from 'cdbreact';
const Products = () => {
  const [username, setUsername] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in by verifying if the username is stored in localStorage
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    } else {
      // If the user is not logged in, redirect to the login page
      navigate("/login");
    }
  }, [navigate]);

  const handleLogout = async () => {
    try {
      const refresh = localStorage.getItem("refreshToken");
      const response = await fetch("http://20.151.63.137/api/logout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh }),
      });

      if (response.ok) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("username");
        setUsername(null);
        navigate("/login");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const scrollToContactUs = () => {
    const element = document.getElementById("contact_us");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
  <nav className="navbar navbar-light bg-dark">
    <div className="container-fluid text-light d-flex justify-content-start align-items-center">
      <div className="navbar-brand text-light">
        {username ? `Welcome, ${username}!` : <Link to="/login" className="text-light">Login</Link>}
      </div>
      <ul className="nav nav-tabs" style={{ marginBottom: 0 }}>
        {/* <li className="nav-item">
          <Link className="nav-link text-light" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-light" to="/products">Products</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-light" to="/services">Services</Link>
        </li>
        <li className="nav-item">
          <a className="nav-link text-light" href="#contact_us" onClick={scrollToContactUs}>Contact Us</a>
        </li> */}
        {username && (
          <li className="nav-item">
            <button className="nav-link btn btn-link text-light" onClick={handleLogout} style={{ cursor: "pointer" }}>
              Logout
            </button>
          </li>
        )}
      </ul>
    </div>
  </nav>
  <div className='container-fluid mx-0 px-0'>
  <CDBSidebar textColor="#333" backgroundColor="">
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
          Contrast Light Mode
        </CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large">Dashboard</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note">Components</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="chart-line" iconType="solid">
              Metrics
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            className="sidebar-btn-wrapper"
            style={{padding: '20px 5px'}}
          >
           
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
  </div>
  
      </div>
  );
};

export default Products;
